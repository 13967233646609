<template>
  <div class="flex flex-column align-center justify-center empty-icon">
    <img src="~@assets/skin1/empty_img.png" />
    <span class="text m-t-5 text-light-gray f-s-14">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "暂无数据",
    },
  },
};
</script>
<style lang="less" scoped>
.empty-icon {
  img {
    width: 106px;
    height: 100px;
  }
}
</style>
