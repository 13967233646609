const BigScreenWorkAreaChart = (data) => {
  let unit = '亩'
  if (data.yData0.some((sub) => sub > 10000)) {
    unit = "万亩"
  }
  let defaultShowNum = 5; // 默认显示的数据条数
  let dataLength = data.xData.length;

  let start = Math.max(0, ((dataLength - defaultShowNum) / dataLength) * 100);
  let end = 100; // 始终以100%结束

  const defaultConfig = {
    legend: {
      bottom: '12',
      icon: "rect",
      itemWidth: 16,
      itemHeight: 1,
      left: "center",
      textStyle: {
        color: "rgba(212, 255, 253, 1)",
        fontSize: 12,
      },
      data: [{
        name: '作业面积',
        itemStyle: {
          color: 'rgba(65, 228, 187, 1)',
        },
      }]
    },
    // 滚动滑块
    dataZoom: [{
        show: true,
        backgroundColor: "rgba(24, 144, 255, 0.20)",
        borderColor: "rgba(24, 144, 255, 0.20)", // 边框颜色
        fillerColor: "rgba(141, 200, 255, 0.40)", // 选中范围背景色
        height: 7,
        xAxisIndex: 0,
        bottom: 3,
        left: 0,
        right: 0,
        brushSelect: false,
        start: start,
        end: end,
        handleSize: 6,
        minSpan: 20, // 最小滑动距离
        showDetail: false,
        filterMode: "filter",
        moveOnMouseMove: true,
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(24, 144, 255, 0.20)",
          },
          areaStyle: {
            color: "rgba(24, 144, 255, 0.20)",
            shadowColor: "rgba(24, 144, 255, 0.20)",
            shadowBlur: 5,
          },
        },
        // 用于画手柄
        handleIcon: "path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5M36.9,35.8h-1.3z M27.8,35.8 h-1.3H27L27.8,35.8L27.8,35.8z", // 画一个圆形
        handleStyle: {
          color: "rgba(24, 144, 255, 0.20)",
          border: '1px solid #6FB1F3',
          global: true, // 缺省为 false
          shadowBlur: 10,
          shadowColor: "rgba(24, 144, 255, 0.20)",
          shadowOffsetX: 0, // 阴影偏移x轴多少
          shadowOffsetY: 0, // 阴影偏移y轴多少
        },
      },
      {
        type: "inside",
      },
    ],
    tooltip: {
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 10
      },
      backgroundColor: "rgba(24,144,255,0.3)", //背景色
      borderColor: "rgba(24,144,255,0.3)", // 边框颜色
      trigger: "axis",
      // axisPointer: {
      //   type: "shadow",
      // },
      formatter: function (params) {
        console.log(`output->params`, params)
        let tooltipString = params[0].name + "<br/>";
        params.forEach(function (item) {
          var icon =
            '<span style="display:inline-block;margin-right:5px;width:8px;height:8px;border-radius:50%;background-color:' +
            item.color +
            '"></span>';
          var seriesName =
            '<span style="color: rgba(255,255,255,0.85);margin-right:20px;font-size:9px;">' +
            item.seriesName +
            "</span>";
          var value =
            '<span style="color:rgba(255,255,255,0.85);font-size:9px;">' +
            item.value +
            "</span>";
          tooltipString +=
            '<div style="padding: 5px; margin-bottom: 5px; border-radius: 4px;">' +
            icon +
            seriesName +
            value +
            "</div>";
        });
        tooltipString += "</div>";
        return tooltipString;
      },
    },
    grid: {
      left: 0,
      right: 0,
      top: '20%',
      bottom: '18%',
      containLabel: true,
    },

    title: {
      text: "单位:" + unit,
      top: 12,
      left: 0,
      textStyle: {
        color: '#A8D6FF',
        fontSize: 12
      },
    },
    xAxis: {
      type: "category",
      data: data.xData,
      axisLine: {
        lineStyle: {
          color: 'rgba(168,214,255,0.7)',
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: 'rgba(168,214,255,0.7)',
          fontSize: 10,
          interval: 0
        },
      },
      axisTick: {
        show: false,
      },
    },

    yAxis: {
      type: "value",
      min: 0,
      minInterval: 1,
      splitLine: {
        lineStyle: {
          color: "rgba(40, 107, 228, 0.20)",
          type: "dotted",
        },
      },
      axisLabel: {
        fontSize: 12,
        color: 'rgba(168,214,255,0.7)',
      },
    },
    series: [{
        name: "作业面积",
        type: "line",
        symbol: data.yData0.length > 1 ? 'none' : 'pin',
        symbolSize: 10, // 设置symbol的大小，例如这里设置为10
        symbolKeepAspect: true, // 保持图形的长宽比
        label: {
          show: false,
        },
        color: 'rgba(65, 228, 187, 1)',
        lineStyle: {
          color: 'rgba(65, 228, 187, 1)',
          width: 2,
        },
        data: data.yData0.some((sub) => sub > 10000) ?
          data.yData0.map((item) => (item / 10000).toFixed(2)) : data.yData0,
      },
      // {
      //   name: "作业面积",
      //   type: "bar",
      //   label: {
      //     show: false,
      //   },
      //   barWidth: "16",
      //   barGap: false,
      //   itemStyle: {
      //     color: {
      //       type: 'linear',
      //       x: 0,
      //       y: 0,
      //       x2: 0,
      //       y2: 1,
      //       colorStops: [{
      //         offset: 0,
      //         color: 'rgba(41, 237, 246, 1)' // 0% 处的颜色
      //       }, {
      //         offset: 1,
      //         color: 'rgba(67, 120, 219, 1)' // 100% 处的颜色
      //       }],
      //     },
      //   },
      //   data: data.yData0,
      // },

    ],
  };

  const opt = Object.assign({}, defaultConfig);
  return opt;
};

export default {
  BigScreenWorkAreaChart,
};