import request from "@utils/request";

// 查询所有系统配置
export default {
  allList(data) {
    return request({
      url: "/nannyCar/all",
      method: "get",
      params: data,
    });
  },
  addCar(data) {
    return request({
      url: "/nannyCar/add",
      method: "post",
      data,
    });
  },
  editCar(data) {
    return request({
      url: "/nannyCar/edit",
      method: "put",
      data,
    });
  },
  deleteCar(data) {
    return request({
      url: "/nannyCar/del",
      method: "delete",
      data,
    });
  },
  //获取每日配药总计
  recordDayList(data) {
    return request({
      url: "/nannyCar/potion/record/day/list",
      method: "get",
      params: data,
    });
  },
  //配药记录
  recordList(data) {
    return request({
      url: "/nannyCar/potion/record/list",
      method: "get",
      params: data,
    });
  },
  //删除配药记录
  delRecordList(data) {
    return request({
      url: "/nannyCar/potion/record/del",
      method: "delete",
      data,
    });
  },
  //获取保姆车下的无人车
  nanArgList(data) {
    return request({
      url: "/agvCar/list",
      method: "get",
      params: data,
    });
  },
  //保姆车下未关联无人车
  nanNoArg(data) {
    return request({
      url: "/agvCar/list/by/company/not",
      method: "get",
      params: data,
    });
  },
  //修改无人车绑定状态
  bindStatus(data) {
    return request({
      url: "/agvCar/bind",
      method: "put",
      data,
    });
  },
};
